<template>
  <header class="main-header container-fluid bg-white">
    <div class="container">
      <div class="row h-100 align-items-center" :class="{ 'justify-content-center': boxed, 'justify-content-between': !boxed }">
        <div class="col-auto d-flex">
          <div class="py-3">
            <Logo :to="{ name: 'projects.list' }" :disable-link="noLinkToHome">ViSEA</Logo>
          </div>
        </div>
        <div class="col">
          <BBreadcrumb v-if="$root.project" class="mb-0 visea-breadcrumbs">
            <BBreadcrumbItem :to="{ name: 'projects.list' }">Progetti</BBreadcrumbItem>
            <BBreadcrumbItem :to="{ name: 'projects.show' }">{{ $root.project.name }}</BBreadcrumbItem>
            <BBreadcrumbItem v-if="$root.module" :to="{ name: 'modules.show' }">{{ $root.module.name }}</BBreadcrumbItem>
            <BBreadcrumbItem v-if="$root.point" :to="{ name: 'points.show' }">{{ $root.point.title }}</BBreadcrumbItem>
          </BBreadcrumb>
        </div>
        <div class="col-auto d-flex align-items-center" v-if="!boxed">
          <div class="d-lg-none">
            <Hamburger class="col-auto d-flex align-items-center d-lg-none px-4"></Hamburger>
          </div>
          <AccountDropdown class="d-none d-lg-block" v-if="isLogged" />
          <div v-else class="ml-3 d-none d-lg-block">
            <router-link :to="{ name: 'login' }">Login</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import rolesMixin from '../../../mixins/roles';

import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue';

export default {
  name: 'layout-admin-header',
  mixins: [rolesMixin],
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
    noLinkToHome: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    AccountDropdown: () => import('../Account/AccountDropdown'),
    Logo: () => import('../Layout/Logo'),
    Hamburger: () => import('../../../libs/Sidebar/components/Hamburger/CssHamburger'),
  },
};

</script>

<style lang="scss">

$main-header-border-bottom-color: #1C2023 !default;
$main-header-box-shadow-color: #1C2023 !default;

.main-header {
    position: relative;
    z-index: 10;
    box-shadow: 0 0 15px rgba($body-color, .2);
}

.visea-breadcrumbs {
  padding: 8px;
  background: rgba($body-color, .05);
  display: inline-flex;
  .breadcrumb-item {
    display: flex;
    align-items: center;
    a, span {
      text-transform: uppercase;
      font-weight: bold;
      display: inline-block;
      padding: 2px 6px;
      border-radius: 3px;
      font-size: 15px;
    }

    a {
      color: $white;
      background-color: rgba($body-color, .4);

      &:hover {
        text-decoration: none;
        background-color: rgba($body-color, .6);
      }
    }

    span {
      color: $white;
      background-color: $primary;
    }

    & + .breadcrumb-item::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f054";
      font-size: 11px;
      color: rgba($body-color, .4);
    }

    &:last-child a {
      pointer-events: none;
      background: $primary;
    }
  }
}
</style>
